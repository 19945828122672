@tailwind components;
@layer components {

  div.list {
    counter-reset: list-number;
  }

  div.list h4:before {
    counter-increment: list-number;
    content: counter(list-number);

    margin-right: 10px;
    margin-bottom: 10px;
    width: 1.6em;
    height: 1.6em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 50%;
    color: #fff;
    @apply bg-brand-100;
  }

  div.list > div {
    margin-bottom: 2em;
    padding-left: 2.3em;
  }

  div.list div.bullet:before {
    content: "";

    margin-right: 10px;
    font-size: 1.2em;
    width: 0.6em;
    height: 0.6em;
    display: inline-flex;
    justify-content: center;
    border-radius: 50%;
    @apply bg-brand-100;

    margin-left: 1em;
  }

  div.list img {
    max-height: 16vw;
    max-width: 50vw;

    margin: 0.3vw;

  }

  div.list img.half {
    display: inline;
    width: 11vw;
    height: 11vw;
  }

  div.list img.wide {
    width: 34.2vw;
  }

  div.list img.tree {
    width: 34.2vw;
  }
}
