@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Husqvarna Gothic';
  font-weight: 400;
  font-style: normal;
  src: url('assets/fonts/Husqvarna-Gothic-Regular.woff2') format('woff2'),
       url('assets/fonts/Husqvarna-Gothic-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Husqvarna Gothic';
  font-weight: 400;
  font-style: italic;
  src: url('assets/fonts/Husqvarna-Gothic-Regular-Italic.woff2') format('woff2'),
       url('assets/fonts/Husqvarna-Gothic-Regular-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Husqvarna Gothic';
  font-weight: 700;
  font-style: normal;
  src: url('assets/fonts/Husqvarna-Gothic-Bold.woff2') format('woff2'),
       url('assets/fonts/Husqvarna-Gothic-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Husqvarna Gothic';
  font-weight: 700;
  font-style: italic;
  src: url('assets/fonts/Husqvarna-Gothic-Bold-Italic.woff2') format('woff2'),
       url('assets/fonts/Husqvarna-Gothic-Bold-Italic.woff') format('woff');
}

@layer base {
  * {
    @apply box-border;
  }

  html {
    @apply h-full;
    @apply w-full;
  }

  body {
    @apply h-full;
    @apply w-full;
    @apply bg-gray-200;
    @apply dark:bg-gray-800;
    @apply text-gray-600;
    @apply dark:text-gray-200;
    @apply font-sans;
    @apply text-base;
    @apply leading-base;
  }

  #root {
    @apply h-full;
    @apply w-full;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-bold;
  }

  h1 {
    @apply text-h1;
    @apply leading-h1;
  }

  h2 {
    @apply text-h2;
    @apply leading-h2;
  }

  h3 {
    @apply text-h3;
    @apply leading-h3;
  }

  h4 {
    @apply text-h4;
    @apply leading-h4;
  }

  h5 {
    @apply text-h5;
    @apply leading-h5;
  }

  h6 {
    @apply text-h6;
    @apply leading-h6;
  }

  a {
    @apply font-bold;
    @apply underline;
  }
}
